.swiper-lazy {
  opacity: 0;
  transition: opacity .6s;
}

.swiper-lazy-loaded {
  opacity: 1;
  transition: opacity .6s;
}
.swiper-pagination {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  z-index: 10;

  @include mqPc {
		position: absolute;
		bottom: 10px;
  }
}

.swiper-pagination-bullet {
  box-sizing: content-box;
  display: block;
	position: relative;
	width: 15px;
	height: 15px;
	margin: 0 2px 3px 2px;
  @include mqPc {
		width: 20px;
		height: 20px;
		margin: 1px 10px 3px 10px;
	}

  &::after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
		background: none;
		border: 1px solid #ddd;
		background: #ddd;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
		transform: translate(-50%, -50%);
		@include mqPc {
			border: 1px solid #888;
			width: 8px;
			height: 8px;
		}
  }

  &.swiper-pagination-bullet-active {
    &::after {
			background: #000A32;
			border-color:  #000A32;
			@include mqPc {
				background:#888;
				border-color:  #888;
			}
    }

  }
}

.swiper-container-fade {
  &.swiper-container-free-mode .swiper-slide {
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
  }

  .swiper-slide {
    pointer-events: none;
    -webkit-transition-property: opacity;
    transition-property: opacity;

    .swiper-slide {
      pointer-events: none;
    }
  }

  .swiper-slide-active {
    pointer-events: auto;

    .swiper-slide-active {
      pointer-events: auto;
    }
  }

  .p-top-mainvisual__link:hover {
    opacity: 1;
    transition: none;
  }
}
