.p-refinesearch__heading {
  display: none;
}

.p-refinesearch__contents {
  display: none;

  @include mqPc {
    display: block;
	}

	.p-sidebar__mainform{
		&~.p-sidebar--banner{
			margin-top: 30px;
			padding-top: 0;
		}
	}
}

.p-sidebar__item {
  padding: 20px;
  height: 0;
  border-bottom: 1px solid #ddd;

  @include mqPc {
    height: auto;
    font-size: 1.3rem;
    line-height: 1.8;
    padding: 0;
    border-bottom: none;
  }

  &[data-accodion="true"] {
    .p-sidebar__link {
      @include mqPc {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .p-sidebar__link {
    @include mqPc {
      position: relative;
    }

    .c-icon--svg {
      display: none;

      @include mqPc {
        position: absolute;
        display: block;
        width: 8px;
        height: 4px;
        stroke: #333;
        position: absolute;
        right: 10px;
        top: calc(50% - 2px);
        transform: rotate(180deg);
      }
    }
  }

  &.is-open {
    @include mqPc {
      background: #eee;

      .p-sidebar__link {
        .c-icon--svg {
          transform: rotate(0deg);
        }
      }
    }
  }
}


body {
  &.is-fixed {
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: -1;
  }
}

.l-container__sidebar {
	position: fixed;
	top: 0;
	left: 0%;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	display: block;
	z-index: -1;
	opacity: 0;
	@include mqPc {
		position: static;
		opacity: 1;
		z-index: auto;
	}

  &.is-open {
		z-index: 100;
		opacity: 1;

    .l-container__sidebar__inner {
      overflow-y: auto;
      height: 100%;
      max-height: calc(100% - 45px);
    }

    .p-refinesearch__contents {
			display: block;
    }

    .p-refinesearch__heading {
      display: block;
    }

    .p-sidebar__section {
      display: flex;
      align-items: flex-start;
      padding: 0;
      margin: 0;
      font-size: 1.6rem;
      line-height: 1.6;
      border-bottom: 1px solid #ddd;
    }

    .p-sidebar__heading {
      display: block;
      flex: 0 0 30.6%;
      font-size: 1.6rem;
      line-height: 1.6;
      font-weight: normal;
      padding: 20px 0 20px 20px;
    }

    .c-form-input {
      padding: 20px 20px 20px 0px;
      width: 100%;

      .c-input {
        &::placeholder {
          font-size: 0;
        }
      }

      &:focus-within {
        &::after {
          font-size: 1.1rem;
        }
      }
    }

    .c-form-button--search {
      top: calc(50% - 9px);
    }

    .p-sidebar__link,
    .p-sidebar__sublist__link {
      pointer-events: none;
    }

    .p-refinesearch__button,
    .p-refinesearch__button--fix {
      display: none;
    }

    .p-sidebar__contents {
			transition: height .3s linear,visibility .3s linear, transform .3s linear;
			z-index: 110;
      &.is-open {
        transform: translateX(0%);
				z-index: 150;
				transition: height .3s linear,visibility .3s linear,transform .3s linear;

        .p-refinesearch__subheading {
          display: block;
        }

        .p-sidebar__list {
          overflow-y: scroll;
          height: 100%;
          max-height: calc(100% - 45px);
        }
      }
    }

    .p-sidebar__list {
      overflow-x: visible;
      transition: height 1s;
      height: 100%;
    }

    .p-sidebar__item {
      height: auto;
    }

    .p-sidebar__link {
      padding: 0;
    }

    .p-sidebar--stock,
    .p-sidebar--color {
      .c-valuebox {
        display: none;
      }
    }

    .p-sidebar--price,
    .p-sidebar--stock,
    .p-sidebar--form {
      align-items: center;
    }

    .c-valuebox {
      display: block;
      width: 100%;
      position: relative;
      z-index: 10;
      padding: 20px 10px;

      &::after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-bottom: 1px solid #333;
        border-right: 1px solid #333;
        transform: rotate(-45deg);
        position: absolute;
        right: 20px;
        top: calc(50% - 4px);
      }
    }

    .p-sidebar--color,
    .p-sidebar--stock,
    .p-sidebar--price,
    .p-sidebar--buttons {
      .p-sidebar__contents__inner {
        position: static;
        transform: none;
        overflow: visible;
        padding: 20px 20px 20px 0;
				width: 100%;
      }
    }

    .p-sidebar--color,
    .p-sidebar--price,
    .p-sidebar--buttons {
      .p-sidebar__item {
        padding: 0;
      }
    }

    .p-sidebar--color,
    .p-sidebar--price,
    .p-sidebar--buttons {
      .p-sidebar__item {
        border-bottom: none;
      }
    }

    .p-sidebar--buttons {
			margin-bottom: 3vh;
			.c-button+.c-button{
				margin-top: 0;
			}
			.p-sidebar__contents__inner{
				padding-left: 20px;
			}
    }
	}

	.p-sidebar--color,
	.p-sidebar--stock,
	.p-sidebar--price,
	.p-sidebar--buttons {
		.p-sidebar__contents{
			position: static;
			transform: none;
		}
	}
}

.p-sidebar--stock {
  .p-sidebar__list {
    display: flex;
    align-items: center;
    justify-content: center;

    @include mqPc {
      display: block;
    }
  }

  .p-sidebar__item {
    border: 1px solid #707070;
    border-radius: 4px;
    font-size: 1.4rem;
    line-height: 1.8;
    flex: 1 1 100px;
    text-align: center;
    padding: 5px;

    @include mqPc {
      border-radius: unset;
      padding: 0;
      font-size: 1.3rem;
      border: none;
      line-height: 1.8;
      text-align: left;
    }

    &:nth-of-type(n+2) {
      margin-left: 24px;

      @include mqPc {
        margin-left: 0;
      }
    }

    .p-sidebar__link {
      padding: 3.5px 2px;
    }

		@include mqSp {
    &.is-select {
      background: #161616;

      .p-sidebar__link {
        color: #fff;
      }
    }
	}
  }
}

.p-sidebar--buttons {
  .p-sidebar__contents__inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.c-valuebox {
  &[data-value="指定なし"] {
    color: #888;
  }

  @include mqPc {
    display: none;
  }
}

.p-sidebar__heading {
  @include mqPc {
    font-size: 1.8rem;
    line-height: 1.3;
    font-weight: bold;
    padding: fslh-spacing(20px, 1.8rem, 1.3) 30px fslh-spacing(20px, 1.8rem, 1.3) 0;
    position: relative;
		cursor: pointer;
  }

  span {
    display: none;

    @include mqPc {
      display: inline-block;
    }
  }

  &.js-accodion--pc {
    @include mqPc {
      position: relative;

			&::before,
			&::after{
				display: block;
				position: absolute;
				background: #333;
				content: "";
				top: 50%;
			}
			&::before{
				width: 10px;
				height: 2px;
				transform: rotate(0deg) translateY(-50%);
				right: 10px;
			}
			&::after{
				width: 2px;
				height: 10px;
				transform: rotate(0deg) translateY(-50%);
				right: 14px;
			}
		}
		&.is-open {
			&::after {
				opacity: 0;
			}
			&+.p-sidebar__contents{
				@include mqPc {
					opacity: 1;
				}
			}
		}
	}
}

.p-sidebar__section {
	&.p-sidebar--banner{
		@include mqPc {
			padding-bottom: 16px;
			padding-top: 3px;
			border-bottom: none;
		}
		&:nth-of-type(n+2){
			@include mqPc {
				margin-top: 32px;
			}
		}
	}
  @include mqPc {
    border-bottom: 1px solid #ddd;
  }

  &:nth-of-type(n+2) {
    margin: 10px 0 0 0;
    padding: 0 0 10px 0;

    @include mqPc {
      margin: 0;
      padding: 0;
    }
	}
	&:not(.p-sidebar--color){
		.p-sidebar__item {
			&.is-select {
				.p-sidebar__link {
					@include mqPc {
						background: #eee;
					}
				}
			}
		}
	}

}

.p-sidebar__contents {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  transform: translateX(100%);
  transition: transform .5s;
	z-index: 15;
	overflow: hidden;

  @include mqPc {
    padding: 0;
    position: static;
    background: none;
		height: auto;
		transform: translateX(0%);
		visibility: visible;
    overflow: unset;
	}
	&__inner{
		@include mqPc {
			padding: 0 0 12px 0;
		}
	}
}

.p-sidebar__contents__inner{
	height: 100%;
	@include mqPc {
		height: auto;
	}
}

.p-sidebar__heading{
	&.js-accodion--pc{
		&+.p-sidebar__contents {
			@include mqPc {
				position: static;
				background: none;
				height: 0;
				transform: translateX(0%);
				visibility: hidden;
				overflow: hidden;
				transition: visibility .3s linear, height .3s linear,overflow .3s linear;
			}
			&__inner{
				@include mqPc {
					padding: 0 0 12px 0;
				}
			}
		}
		&.is-open {
			&+.p-sidebar__contents{
				@include mqPc {
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}
}



.p-sidebar--color,
.p-sidebar--price {
  .p-sidebar__contents__inner {
    @include mqPc {
      padding-bottom: 20px;
    }
  }
}

.p-sidebar--color {
  .p-sidebar__list {
    @include flex-left-top;
    flex-wrap: wrap;

    @include mqPc {
      padding: 20px 10px 0px 10px;
    }
  }

  .p-sidebar__item {
    width: 25%;
    text-align: center;
    position: relative;

    @include mqPc {
      margin-bottom: 7px;
    }

    span {
      font-size: 1rem;
      line-height: 2.6;
      text-align: center;

      @include mqPc {
        display: none;
      }
    }

    &.is-select {
      .p-sidebar__link {
        position: relative;

        @include mqPc {
          overflow: visible;
        }

        &:before {
          content: "";
          display: block;
          background: transparent;
          width: 48px;
          height: 48px;
          position: absolute;
          top: -5.5px;
          left: -5px;
          border: 1px solid #161616;
          border-radius: 50%;

          @include mqPc {
            top: -3px;
            left: -2.4px;
            width: 37px;
            height: 37px;
          }
        }
      }
    }

    @include mqPc {
      &:hover {
        span {
          display: block;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -100%);
          background: #161616;
          color: #fff;
          text-align: center;
          font-size: 1rem;
          line-height: 2;
          width: 150%;

          &::after {
            width: 5px;
            height: 5px;
            display: block;
            content: "";
            position: absolute;
            top: 100%;
            left: calc(50% - 5px);
            border-top: 5px solid #161616;
            border-right: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid transparent;
            text-align: center;
          }
        }
      }
    }
  }

  .p-sidebar__link {
    width: 40px;
    height: 40px;
    display: block;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    border: 1px solid #fff;

    @include mqPc {
      width: 33px;
      height: 33px;
    }

    &.color01 {
      border: 1px solid #ddd;
      position: relative;

      &::after {
        content: "";
        width: 100%;
        height: 1px;
        display: block;
        transform: rotate(-45deg);
        position: absolute;
        top: 50%;
        left: 0;
        background: #E03434;
      }
    }

    &.color02 {
      border: 1px solid #ddd;
    }

    &.color03 {
      background: #313131;
    }

    &.color04 {
      background: #BDBDBD;
    }

    &.color05 {
      background: #795548;
    }

    &.color06 {
      background: #B18B55;
    }

    &.color07 {
      background: #E0D2AD;
    }

    &.color08 {
      background: #CDDC39;
    }

    &.color09 {
      background: #66BB6A;
    }

    &.color10 {
      background: #00BFA5;
    }

    &.color11 {
      background: #42A5F5;
    }

    &.color12 {
      background: #3A8DAA;
    }

    &.color13 {
      background: #3F51B5;
    }

    &.color14 {
      background: #AB47BC;
    }

    &.color15 {
      background: #FFEE58;
    }

    &.color16 {
      background: #FD77A9;
    }

    &.color17 {
      background: #EF5350;
    }

    &.color18 {
      background: #FEA729;
    }

    &.color19 {
      background: rgb(160, 160, 160);
      background: linear-gradient(360deg, rgba(160, 160, 160, 1) 0%, rgba(239, 239, 239, 1) 100%);
    }

    &.color20 {
      background: rgb(193, 171, 27);
      background: linear-gradient(360deg, rgba(193, 171, 27, 1) 0%, rgba(252, 247, 219, 1) 100%);
    }

    &.color21 {
      background: rgb(255, 248, 75);
      background: linear-gradient(360deg, rgba(255, 248, 75, 1) 0%, rgba(78, 255, 85, 1) 20%, rgba(72, 241, 255, 1) 40%, rgba(66, 81, 252, 1) 60%, rgba(253, 81, 249, 1) 80%, rgba(253, 13, 27, 1) 100%);
    }
	}
	.is-open{
		&+.p-sidebar__contents{
			@include mqPc {
			margin-top: -20px;
			}
		}
	}
}

.p-sidebar__sub__contents {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  transform: translateX(100%);
  transition: transform .5s;

  @include mqPc {
    position: static;
    transform: none;
    transition: none;
    height: 0;
		width: auto;
		overflow-y: hidden;
		transition: visibility .3s linear,height .3s linear,overflow .3s linear;
  }

  &.is-open {
    transform: translateX(0);
    transition: transform .5s;
    z-index: 200;

    .p-sidebar__sublist {
      height: 100%;
      max-height: calc(100% - 45px);
      overflow-y: scroll;

      @include mqPc {
        overflow-y: visible;
      }
    }
  }
}

.p-sidebar__sublist {
  @include mqPc {
    font-size: 1.1rem;
    line-height: 2;
  }
}

.p-sidebar__sublist__link {
  @include mqPc {
    padding: fslh-spacing(11px, 1rem, 2) 0px fslh-spacing(11px, 1rem, 2) 14px;
    display: block;
  }
}

.p-sidebar__sublist__item {
  padding: 20px;
  border-bottom: 1px solid #ddd;

  @include mqPc {
    padding: 0;
    border: none;
  }

  &.is-select {
    &>.p-sidebar__sublist__link {
      @include mqPc {
        background: #eee;
      }
    }
  }
}

.p-sidebar--form {
  @include mqPc {
		padding-bottom: 15px;
  }
}

.p-sidebar__link {
  padding: 3px 10px;
  display: block;

  @include mqPc {
    padding: 3px 12px;
    color: #555;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;

    &:hover {
      background: #eee;
    }
  }
}

.c-button--return {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px 10px 10px 15px;

  &::before {
    display: block;
    width: 7px;
    height: 7px;
    transform: rotate(45deg);
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
    content: "";
    position: absolute;
    top: calc(50% - 3.5px);
    left: 2px;
  }
}

.c-button--return {
  font-size: 1.4rem;
  line-height: 1.28;
}

.p-refinesearch__heading,
.p-refinesearch__subheading {
  font-size: 1.6rem;
  line-height: 1.2;
  background: #000;
  color: #fff;
  padding: fslh-spacing(15px, 1.6rem, 1.2) 20px;
  position: relative;

  @include mqPc {
    display: none;
  }
}

.p-refinesearch__heading,
.p-refinesearch__subheading {
  p {
    text-align: center;
  }
}

.p-refinesearch__button {
  background: #000;
  color: #fff;
  font-size: 1.4rem;
  line-height: 1.6;
  position: relative;
  display: block;
  width: 100%;
  padding: calc(14px - ((1.4rem * 1.6) - 1.4rem)/ 2) 35px;
  margin: 23px 0 0 0;

  @include mqTb {
    padding: 1.63vw 4.15vw;
  }

  @include mqPc {
    display: none;
  }

  &::after {
    content: "";
    top: calc(50% - 3.5px);
    display: block;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    transform: rotate(-45deg);
    position: absolute;
    right: 13px;
    width: 7px;
    height: 7px;
  }

  .c-icon-svg {
    position: absolute;
    left: 17px;
    top: calc(50% - 7px);
  }
}

.p-refinesearch__button--fix {
  width: 48px;
  height: 48px;
  display: block;
  border-radius: 4px;
  background: rgba(0, 0, 9, 0.8);
  padding: 10px 3px;
  position: fixed;
  bottom: 2.088%;
  right: calc(4% + 63px);
  color: #fff;
  font-size: 1rem;
  text-align: center;
  transform: translateY(0%);
  transition: transform .5s;
  z-index: 10;

  @include mqTb {
    width: 9.6vw;
    height: 9.6vw;
    right: 15.5vw;
  }

  @include mqPc {
    display: none;
  }

  .c-icon-svg {
    display: block;
    margin: 0 auto 5px auto;
  }

  &.is-hide {
    transform: translateY(100px);
    transition: transform .5s;
    pointer-events: none;

    @include mqTb {
      transform: translateY(20vw);
    }
  }
}

.p-sidebar--form {
  .p-sidebar__heading {
    display: none;

    @include mqPc {
      display: block;
      padding-top: 0;
    }
  }
}

.c-form-input {
  position: relative;
  padding: 3px 0 0 0;



  .c-input {
    width: 100%;
    border-radius: 4px;
    padding: calc(10px - ((1.4rem * 1.4) - 1.4rem)/ 2) 10px;
    font-size: 1.4rem;
    line-height: 1.4;
  }

  .c-form-button--search {
    position: absolute;
    top: calc(50% - 8px);
    right: 30px;
    width: 20px;
    height: 20px;

  }
}

.p-sidebar--buttons {
  .c-button {
    font-size: 1.4rem;
    line-height: 1.3;
    border-radius: 4px;
    padding: fslh-spacing(15px, 1.4rem, 1.3) 10px;
	}
	.c-button--submit {
		background: #161616;
		color: #fff;
		flex: 1 1 225px;
		border: 1px solid #000;
		margin-right: 0;
	}

	.c-button--reset {
		border: 1px solid #333;
		margin-right: 10px;
		margin-left: 0;
		flex: 1 1 100px;
	}
}


.p-sidebar__list {
  transition: height 1s;
  height: 0;

  @include mqPc {
    overflow: visible;
    height: auto;
  }
}

.p-sidebar--price {
  .p-sidebar__contents {
    @include mqPc {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
}

.c-priceselect--item{
	@include mqPc {
		text-align: center;
  }
}

.c-priceselect {
  display: flex;
  justify-content: center;
  align-items: center;

  @include mqPc {
    margin-top: 22px;
    padding: 0 10px
  }
}

.c-price--bar {
  display: none;

  @include mqPc {
    display: block;
    width: calc(100% - 20px);
    margin: 8px auto 0 auto;
    padding: 0 5px 0 5px;
  }
}

.c-priceselect--item {
  span {
    font-size: 1.4rem;
    line-height: 2.16;

    @include mqPc {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
}

.c-priceselect--max,
.c-priceselect--min {
  background: none;
  border: 1px solid #EBEBEB;
  border-radius: 4px;
  width: calc(71% - 5px);
  margin-right: 5px;
  text-align: center;

  @include mqPc {
    margin-right: 1px;
    width: calc(70% - 5px);
    font-size: 1.3rem;
    line-height: 1.8;
  }
}



.c-priceselect__button {
  @include mqPc {
		display: flex;
		justify-content: space-between;
    margin-top: 12px;
		text-align: center;
		padding: 0 6px 0 8px;
  }

  .c-button {
    border-radius: 4px;
    font-size: 1rem;
    line-height: 2.6;

    @include mqPc {
			line-height: 2.2;
			padding-top: 0;
			font-size: 1.2rem;
			padding-bottom: 0;
    }
  }

  .c-button--reset {
    @include mqPc {
      width: 56px;
			margin-right: 5px;
			margin-left: 5px;
    }
  }

  .c-button--submit {
    @include mqPc {
			width: 80px;
			margin-top: 0;
    }
  }
}
