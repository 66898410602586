@import "./../../foundation/variables";
@import "./../../foundation/mixin";

.p-productList{
	&__list{
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: flex-start;
			width: 100%;
			padding: 0 15px;
			margin-left: auto;
			margin-right: auto;

			@include mqPc {
				padding-left: 0;
				padding-right: 0;
				margin-left: -10px;
				width: calc(100% + 20px);
			}
			@include mqTb {
				width: calc(100% + 0px);
			}
	}
	&--spCarousel{
		padding: 0 15px;
		@include mqPc {
			padding: 0;
		}
		.p-productList__list{
			flex-wrap: nowrap;
			padding: 0;
		}
		.c-productList__item{
			flex: none;
			max-width: unset;
			margin-left: 0;


			@include mqPc {
				flex: 0 1 calc(16.66% - 20px);
				width: calc(16.66% - 20px);
				max-width: calc(16.66% - 20px);
				margin-left: 10px;
			}
			@include mqSp {
			&:nth-of-type(n+3){
				margin-top: 0;
			}
		}
		}
	}
}

.p-productList__list{
	&.is-columnChanger--1{
		.c-productList__item{
			flex: 0 0 calc(100% - 10px);
			width: calc(100% - 10px);
			max-width: calc(100% - 10px);
			&:nth-of-type(n+2){
				margin-top: 15px;
			}
		}
	}
}

.product-list{
	.p-productList__list{
		.c-productList{
			&__item{
				@include mqPc {
					&:nth-of-type(n+5){
						margin-top: 30px;
					}
				}
			}
		}
	}
}

.c-productList{
	&__item{
		flex: 0 0 calc(50% - 10px);
		max-width: calc(50% - 10px);
		width: calc(50% - 10px);
		position: relative;
		margin-left: 5px;
		margin-right: 5px;
		&:nth-of-type(n+3){
			margin-top: 25px;
		}
		@include mqTb {
			margin-left: 5px;
			margin-right: 5px;
			flex: 0 1 calc(33.3% - 10px);
			width: calc(33.3% - 10px);
			max-width: calc(33.3% - 10px);
			&:nth-of-type(3){
				margin-top: 0px;
			}
			&:nth-of-type(n+4){
				margin-top: 40px;
			}
		}
		@include mqPc {
			margin-left: 10px;
			margin-right: 10px;
			flex: 0 1 calc(25% - 20px);
			width: calc(25% - 20px);
			max-width: calc(25% - 20px);
			&:nth-of-type(-n+4){
				margin-top: 0px;
			}
		}

		&--sold{
			.c-productList__price{
				color: #ccc;
			}
		}

	}
	&__link{
		color: #333;
		display: block;
		position: relative;
		z-index: 1;
	}
	&__image{
		&+.c-productList__brand{
			margin-top: 9px;
		}
	}
	&__brand{
		font-size: 1.1rem;
		line-height: 2;
		color: #1C1C1C;
		word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	}
	&__name{
		font-size: 1.2rem;
		line-height: 1.6;
		word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
		@include mqPc {
			line-height: 1.83;
		}
		&+.c-productList__price{
			margin-top: 7px;
			@include mqPc {
				margin-top: 4px;
			}
		}
	}
	&__price{
		font-size: 1.6rem;
		line-height: 1.375;
		font-family: $baseFontsFamilyEN;
		font-weight: bold;
		position: relative;
		@include mqPc {
			font-size: 1.6rem;
			line-height: 1.375;
		}
		&--sale{
			color: #D50000;
		}
		&+.c-productList__stuts{
			margin-top: 7px;
			@include mqPc {
				margin-top: 10px;
			}
		}
	}
	&__button{
		position: absolute;
		right: 0;
		bottom: 3px;
		display: flex;
		z-index: 5;
		@include mqPc{
			right: 10px;
			bottom: 2px;
		}
		&.is-select{
			.c-icon--favorite{
				fill: #F44336;
			}
		}
		.c-icon--favorite{
			fill: #ddd;
		}
	}
	&__offrate{
		margin-left: 5px;
	}

	&__stuts{

	}
}

.c-tag{
	&__list{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
	}
	&__item{
		width: calc(50% - 5px);
		font-size: 1rem;
		line-height: 2;
		text-align: center;
		border: 1px solid #000;
		&--sale{
			color: #D50000;
			border-color: #D50000;
		}
		&--sold{
			color: #ccc;
			border: none;
			text-align: left;
			line-height: 1.83;
			font-size: 1.2rem;
		}
	}
}

.p-searchbox{
	&+.p-related-keywords{
		margin-top: 24px;
	}
}

.p-related-keywords{
	&__heading{
		font-size: 1.4rem;
		line-height: 1.33;
		color: #1C1C1C;
		font-weight: bold;
		&+.p-related-keywords__list{
			margin-top: 12px;
		}
	}
	&__list{
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;
		margin-bottom: -2.81%;
		@include mqPc{
			margin-bottom: -10px;
		}
	}
	&__item{
		font-size: 1.2rem;
		line-height: 1.33;
		color: #444;
		margin: 0 2.81% 2.81% 0;
		border-radius: 4px;
		background: #eee;
		@include mqPc{
			margin: 0 10px 10px 0;
		}
	}
	&__link{
		padding: 10px 16px;
		display: block;
	}
}
.c-flex{
	&--colum02,
	&--colum03,
	&--colum04,
	&--colum05,
	&--colum06{
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: flex-start;
	}
	&--colum02{

	}
	&--colum03{

	}
	&--colum04{

	}
	&--colum06{
		&>*{
			width: 16.667%;
		}
	}
}

.p-list{
	&--arrow{
		.p-list__item{
			border-bottom: 1px solid #ddd;
			@include mqPc {
				border-bottom: 1px solid #f8f8f8
			}
		}
		.p-list__link{
			display: block;
			padding: 18px 50px 18px 20px;
			position: relative;
			&::after{
				content: "";
				display: block;
				width: 12px;
				height: 12px;
				position: absolute;
				border-top: 1px solid #333;
				border-right: 1px solid #333;
				transform: rotate(45deg) translateY(-50%);
				top: 50%;
				right: 25px;
			}
		}
	}
	.p-list__item{

	}

}

.c-list{
	&--colmun02-04{
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: flex-start;
		width: calc(100% + 20px);
		margin-left: -10px;
		padding-left: 5px;
		padding-right: 5px;
		@include mqTb {
			width: calc(100% + 20px);
			margin-left: -10px;
		}
		@include mqPc{
			width: calc(100% + 20px);
			margin-left: -10px;
		}
		.c-list__item{
			margin: 0 5px;
			flex: 0 0 calc(50% - 10px);
			width: calc(50% - 10px);
			max-width: calc(50% - 10px);
			text-align: center;
			font-size: 1.4rem;
			line-height: 1.6;
			color: #1c1c1c;
			@include mqSp{
				&:nth-of-type(n+3){
					margin-top: 30px;
				}
			}
			@include mqPc{
				width: calc(25% - 20px);
				flex: 0 1 calc(25% - 20px);
				width: calc(25% - 20px);
				margin: 0 10px;
				line-height: 1.83;
				&:nth-of-type(n+5){
					margin-top: 35px;
				}
			}
			@include mqTb {
				margin: 0 5px;
				flex: 0 1 calc(33.3% - 10px);
				width: calc(33.3% - 10px);
				max-width: calc(33.3% - 10px);
				&:nth-of-type(n+4){
					margin-top: 40px;
				}
			}

			.c-link{
				display: block;
			}
			.c-time{
				display: block;
				font-family: $baseFontsFamilyEN;
			}
			.c-link{
				color: #1c1c1c;
			}
		}
	}
	&--colmun02-03{
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: flex-start;
		width: calc(100% + 20px);
		margin-left: -10px;
		padding-left: 5px;
		padding-right: 5px;
		@include mqTb {
			width: calc(100% + 20px);
			margin-left: -10px;
		}
		@include mqPc{
			width: calc(100% + 40px);
			margin-left: -20px;
		}
		.c-list__item{
			margin: 0 5px;
			flex: 0 0 calc(50% - 10px);
			width: calc(50% - 10px);
			max-width: calc(50% - 10px);
			font-size: 1.2rem;
			line-height: 1.6;
			color: #1c1c1c;
			@include mqSp{
				&:nth-of-type(n+3){
					margin-top: 30px;
				}
			}
			@include mqPc{
				flex: 0 1 calc(33.3% - 40px);
				width: calc(33.3% - 40px);
				max-width: calc(33.3% - 40px);
				margin: 0 20px;
				line-height: 1.83;
				&:nth-of-type(n+4){
					margin-top: 40px;
				}
			}
			@include mqTb {
				margin: 0 5px;
				flex: 0 1 calc(33.3% - 10px);
				width: calc(33.3% - 10px);
				max-width: calc(33.3% - 10px);
				&:nth-of-type(n+4){
					margin-top: 40px;
				}
			}

			.c-link{
				display: block;
			}
			.c-time{
				display: block;
				font-family: $baseFontsFamilyEN;
				font-size: 1rem;
				line-height: 1.9;
				color: #555;
				margin-top: 3px;
				@include mqPc{
					color: #888;
				}
			}
			.c-link{
				color: #1c1c1c;
			}
		}
	}
	&__text{
		margin-top: 7px;
	}

}

.p-archiveList{
	&__item{
		font-size: 1.4rem;
		line-height: 1.35;
		border-bottom: 1px solid #ddd;
		&:nth-of-type(1){
			border-top: 1px solid #ddd;
		}
	}
	&__link{
		display: block;
		padding: 15px 40px 15px 0;
		position: relative;
		@include mqPc{
			padding: 50px 60px 50px 0;
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
		&::after{

		}
	}
	.c-icon--arrowR{
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%) rotate(90deg);
	}
	&__text{
		margin-top: 3px;
		overflow: hidden;
		max-height: calc((1.4rem * 1.35) * 2);
		text-align: justify;
		position: relative;
		@include mqPc{
			flex: 1 0 calc(100% - 125px);
			width: calc(100% - 125px);
			font-size: 1.6rem;
			margin-top: 0;
			line-height: 1.18;
		}
		&::before{
			right: 0;
			bottom: 0;
			content: "…";
		}
		&::after{
			width: 100%;
			height: 100%;
			content: "";
		}
		&::before,
		&::after{
			position: absolute;
			background: #fff;
		}
	}
	&__time{
		font-size: 1rem;
		line-height: 1.9;
		color: #888;
		@include mqPc{
			flex: 1 0 125px;
			width: 125px;
			padding-right: 20px;
			font-size: 1.2rem;
			line-height: 1.58;
		}
	}
	&+.p-pagenation{
		padding-left: 0;
		padding-right: 0;
	}
}
