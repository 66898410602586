.c-more{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 31px;
	font-size: 1.6rem;
	line-height: 1.25;
	@include mqPc {
		margin-top: 40px;
	}
	&__link{
		display: flex;
    align-items: center;
    justify-content: center;
	}
	.c-icon--arrow{
		transform: rotate(90deg);
		margin-left: 6px;
		@include mqPc {
			margin-left: 10px;
		}
	}
}

.c-form__link--attention{
	color: #1E88E5;
}

.is-inactive{
	pointer-events: none;
}

.c-link{
	color: #1E88E5;
	text-decoration: underline;
}
