//  setting
@import "variables";

html {
	font-family: $baseFontsFamily;
	font-size: 62.5%;
	line-height: $baselineHeight;
	color:$baseFontsColor;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
	word-break: break-all;
}

body{
	font-size: 1.4rem;
	width: 100%;
	min-width: 320px;
}

a {
	color: #333;
	text-decoration: none;
	@include mqPc {
		transition: opacity .3s;
		&:hover{
			opacity: .6;
			transition: opacity .3s;
		}
	}

}

img{
	width: 100%;
	max-width: 100%;
	height: auto;
	vertical-align: middle;
	border-style: none;
	&.lozad{
		opacity: 0;
		transition: opacity .6s;
	}
	&[data-loaded="true"]{
		opacity: 1;
		transition: opacity .6s;
	}
}

ol, ul {
  list-style: none;
  padding-left: 0;
}

input {
  box-shadow: none;
  -webkit-appearance: none;
	border-radius: 0;
	background: #eee;
}

button {
  outline: none;
}

address, caption, cite, code, dfn, em, th, var {
  font-style: normal;
  font-weight: normal;
}

h1,h2,h3,h4,h5,h6{
	font-weight: bold;
}

a,button,label{
	cursor: pointer;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance:textfield;
}
