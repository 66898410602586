.p-gallery{
	&__conrainer{
		@include mqPc {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}
	}
	&__images{
		@include mqPc {
			flex: 0 1 48%;
			max-width: 48%;
			width: 48%;
		}
	}
	&__image{

	}
	&__info{
		margin-top: 25px;
		@include mqPc {
			flex: 0 1 48%;
			max-width: 48%;
			width: 48%;
			margin-top: 0;
		}
		.c-time{
			font-size: 1.2rem;
			line-height: 1.5;
			color: #888;
			&+.p-gallery__heading{
				@include mqPc {
					margin-top: 30px;
				}
			}
		}
		.p-media{
			padding: 30px 0;
			border-top: none;
			@include mqPc {
				margin-top: 10px;
				padding: 10px 0 20px 0;
			}
		}
		.p-media__list{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: flex-start;
		}
		.p-media__link{
			display: block;
			padding: 0;
			@include mqPc {
				display: flex;
				align-items: center;
			}
		}
		.p-media__price{
			color: #888;
			margin-top: 6px;
		}
		.p-media__item{
			flex: 0 1 calc(50% - 10px);
			max-width: calc(50% - 10px);
			width: calc(50% - 10px);
			font-size: 1.2rem;
			line-height: 1.6;
			border-bottom: none;
			padding: 0;
			text-align: center;
			@include mqSp {
				&:nth-of-type(n+3){
					margin-top: 20px;
				}
			}
			@include mqPc {
				text-align: left;
				flex: 0 1 100%;
				max-width: 100%;
				width: 100%;
				margin-right: 0;
				border-right: 0;
				&:nth-of-type(n+2){
					margin-top: 20px;
				}
			}
		}

		.p-media__images{
			width: 100%;
			max-width: 100%;
			margin-right: 0;
			@include mqPc {
				flex: 0 1 20%;
				max-width: 20%;
				width: 20%;
			}
		}
		.p-media__info{
			flex: 1 1 100%;
			width: 100%;
			max-width: 100%;
			margin-top: 10px;
			@include mqPc {
				flex: 0 1 80%;
				max-width: 80%;
				width: 80%;
				padding-left: 20px;
			}
		}
	}
	&__heading{
    margin-top: 5px;
    font-size: 1.6rem;
		line-height: 1.625;
		@include mqPc {
			font-size: 1.8rem;
			line-height: 1.611;
		}
		&+.p-gallery__subHeading{
			margin-top: 40px;
			@include mqPc {
				margin-top: 32px;
			}
		}
	}
	&__subHeading{
    font-size: 2rem;
		line-height: 1;
		text-align: center;
		@include mqPc {
			font-size: 1.6rem;
		}
	}
	&__box{
	}
	&__text{
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
		padding: 20px 0;
		@include mqPc {
			margin-top: 32px;
			padding: 32px 0;
		}
	}
}

.l-lower-container__inner{
	.p-gallery__images{
		width: calc(100% + 40px);
		margin-left: -20px;
		@include mqPc {
			width: calc(100% + 40px);
			margin-left: 0px;
		}
	}
}

.l-main{
	&>.c-lower-header{
		padding-bottom: 0;
	}
}
