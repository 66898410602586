.l-footer {
	background: #eee;
	width: 100%;
	overflow: hidden;
  @include mqPc {
    padding: fslh-spacing(50px, 1.6rem, 1.4) 0 0 0;
	}
	&__inner{
		width: 89.33%;
		margin-left: auto;
		margin-right: auto;
		@include mqPc {
			display: flex;
			justify-content:space-between;
			align-items: flex-start;
			width: 100%;
			max-width: 1020px;
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}

.p-footer-sns{
	&__list{
		display: flex;
		justify-content: center;
		align-items: center;
		@include mqPc {
			margin-left: -13px;
		}
	}
	&__item{

	}
	&__link{
		padding: 7.5px;
		display: block;
		line-height: 1;
		margin: 0 7.5px;
	}
}


.p-footer__contents{

	&--category{
		@include mqPc {
			flex: 0 1 174px;
			max-width: 174px;
			padding-right: 20px;
		}
		.p-footer-navigation{
			height: auto;
		}
	}
	&--content{
		@include mqPc {
			flex: 0 1 194px;
			max-width: 194px;
			padding-right: 20px;
		}
	}
	&--help{
		@include mqPc {
			flex: 0 1 205px;
			max-width: 205px;
			padding-right: 20px;
		}
	}
	&--sns{
		display: flex;
		flex-wrap: wrap;
		margin-top: 55px;
		@include mqPc {
			margin-top: 0;
			flex: 0 1 229px;
			max-width: 229px;
			padding-right: 20px;
		}
	}
}

.p-footer__heading{
	padding: 21px 36px 21px 0;
	margin-top: 13px;
	font-size: 2rem;
	line-height: 1.6;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #ddd;
	font-family: $baseFontsFamilyEN;
	letter-spacing: .08em;
	@include mqPc {
		border-bottom: none;
		flex: 1 0 100%;
		width: 100%;
		padding: 0;
		margin-top: 0;
	}
	.c-icon--arrowB{
		stroke-width: 2px;
		width: 14px;
		height: 7px;
		@include mqPc {
			display: none;
		}
	}
	&.is-open{
		&+.p-footer-navigation{
			overflow: hidden;
			transition: height linear .3s;
		}
		.c-icon--arrowB{
			transform: translateX(24px) rotate(0deg);
		}
	}
}
.p-footer-navigation{
	height: 0;
	overflow: hidden;
	transition: height linear .3s;
	@include mqPc {
		height: auto;
		overflow: unset;
	}
	&__list{
		@include mqPc {
			margin-top: 20px;
		}
	}
	&__item{
		border-bottom: 1px solid #ddd;
		@include mqPc {
			border-bottom: none;
			font-size: 1.4rem;
			&:nth-of-type(n+2){
				margin-top: 20px;
			}
		}
	}
	&__link{
		padding: 20px 36px 20px 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include mqPc {
			padding: 0;
		}
		.c-icon--arrowR{
			@include mqPc {
				display: none;
			}
		}
	}
}




.p-footer__logo{
	order: 1;
	width: 180px;
	height: 29px;
	margin-left: auto;
	margin-right: auto;
	flex: 1 0 100%;
	@include mqPc {
		order: 2;
    height: 31px;
    flex: 0 1 182px;
    margin-left: 0;
	}
	.c-svg{
		width: 100%;
		height: 100%;
	}
	&+.p-footer-sns{
		margin-top: 47.5px;
		@include mqPc {
			margin-top: 8px;
			margin-bottom: 85px;
		}
	}
}
.p-footer-sns{
	order: 2;
	flex: 1 0 100%;
	@include mqPc {
		order: 1;
	}
}



.p-footer-bottom{
	margin-top: 40px;
	@include mqPc {
		background: #1C1C1C;
		margin-top: 120px;
	}
	&__inner{
		@include mqPc {
			width: 100%;
			padding: 0 20px;
			max-width: 1040px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
.p-footer-menu{
	&__list{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		max-width: 260px;
		margin-left: auto;
		margin-right: auto;
		@include mqPc {
			max-width: none;
			margin-left: -10px;
		}
	}
	&__item{
		font-size: 1.2rem;
		line-height: 2.6;
		position: relative;
		@include mqPc {
			&::after{
				height: 9px;
				width: 1px;
				background: #fff;
				display: block;
				content: "";
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				}
		}
		&:nth-of-type(1){
			&::after{
				height: 9px;
				width: 1px;
				background: #1C1C1C;
				display: block;
				content: "";
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				@include mqPc {
					background: #fff;
				}
			}
		}
		&:nth-last-of-type(1){
			&::after{
				display: none;
			}
		}
	}
	&__link{
		padding: 0 20px;
		@include mqPc {
			color: #fff;
			text-decoration: underline;
		}
	}
}

.p-footer__copyright{
	background: #1C1C1C;
	text-align: center;
	margin-top: 50px;
	@include mqPc {
		background: none;
		margin-top: 0;
	}
	&__inner{
		padding: 1px 10px;
	}
	small{
		color: #fff;
		display: block;
		font-size: 1.2rem;
		line-height: 2.6;
	}
}


.c-pagetop {
  background: rgba(0, 0, 9, .8);
  display: inline-block;
  border-radius: 4px;
  position: fixed;
  bottom: 2.088%;
  right: 4%;
  transition: transform .5s;
	transform: translateY(100px);
	z-index: 10;

  @include mqTb {
    width: 9.6vw;
    height: 9.6vw;
    transform: translateY(20vw);
  }

  &.is-show {
    transition: transform .5s;
    transform: translateY(0);
  }

  .c-pagetop__link {
    display: block;
    padding: 20px 16px;

    @include mqTb {
      padding: 0;
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .c-icon--svg {
    width: 16px;
    height: 8px;
    stroke: #fff;
    display: block;
    pointer-events: none;

    @include mqTb {
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}


