.l-headNews{
	background: #1C1C1C;
	font-size: 1.2rem;
	line-height: 1.7;
}
.p-headNews{
	&__link{
		padding: 10px 5px;
		display: block;
	}
	&__text{
		color: #fff;
		text-align: center;
	}
}

.l-header{
	position: sticky;
	top: 0;
	width: 100%;
	background: #fff;
	z-index: 100;
	&::after{
    box-shadow: 0 4px 8px rgba(0,0,0,.2);
    content: "";
    position: absolute;
    width: 100%;
    height: auto;
    display: block;
    z-index: 101;
		top: 0;
		bottom: 0;
    left: 0;
    pointer-events: none;
	}
	&__inner{
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		@include mqPc {
			flex-wrap: wrap;
			justify-content: center;
			align-items: flex-start;
			padding-top: 27px;
		}
	}
	&.is-open{
		.p-drwer{
			display: block;
			z-index: 1;
			visibility: visible;
			/*
			&__toggle{
				span{
					&:nth-of-type(1),
					&:nth-of-type(3){
						transform: rotate(-45deg) translateX(-50%);
						left: calc(50% - 2px);
						top: 1px;
						transform-origin: 50% 50%;
					}
					&:nth-of-type(2){
						transform: translateX(-300%);
					}
					&:nth-of-type(3){
						transform: rotate(45deg) translateX(-50%);
						left: calc(50% - 2px);
						top: 0px;
						transform-origin: 50% 50%;
					}
				}
			}
			*/
			.p-header-navigation{
				transform: translateX(0%);
				transition: transform .4s linear;
			}
			.p-header-navigation__inner{

			}
			.p-header-navigation__list{
				padding-bottom: 180px;
			}
			&__contents{
				opacity: 1;
				display: block;
				height: 100vh;
				transition: opacity .2s ease-in;
				position: relative;
			}
		}
	}
}

.p-header__logo{
	width: 100%;
	max-width: 131px;
	height: 100%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	@include mqPc {
    max-width: 190px;
    height: 30px;
		transform: translateX(-50%);
		top: auto;
	}
	a{
		display: block;
		height: 100%;
		padding: 0 10px;
	}
	svg{
    width: 100%;
    height: 100%;
	color: #000;
	}
}

.p-header-tool{
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 10px;
	margin-left: auto;
	@include mqPc {
		flex: 0 1 100%;
	}
	&__list{
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	&__item{
		&--search{
			.is-open{
				&+.p-header-dropdown{
					visibility: visible;
					z-index: 1;
					opacity: 1;
					transition: opacity .3s ease,visibility .3s ease;
					&::before{
						content: "";
						display: block;
						width: 100%;
						height: 100vh;
						top: 0;
						left: 0;
						background: rgba(0,0,0,.5);
						position: absolute;
						z-index: 0;
						@include mqPc {
							display: none;
						}
					}
				}
			}
			@include mqPc {
					.p-header-dropdown__container{
						width: 100%;
						max-width: 690px;
						margin-left: auto;
						margin-right: auto;
					}
				}
		}
		&--help{
			display: none;
			@include mqPc {
				display: block;
			}
		}
		&--user{
			display: none;
			@include mqPc {
				display: block;
			}
		}
		&--cart{
			position: relative;
			.p-cart__num{
				position: absolute;
				font-size: 1rem;
				line-height: 1.7;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-35%);
			}
		}
		&.is-open{
			.p-header-dropdown{
				visibility: visible;
				transition: opacity .3s ease, visibility .3s ease;
				z-index: 1;
				opacity: 1;
			}
		}
	}
	&__link{
		padding: 7.5px;
		line-height: 1;
		display: block;
		@include mqPc {
			padding-left: 12px;
			padding-right: 12px;
		}
	}
}

.p-drwer{
	position: absolute;
	top: 100%;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: -1;
	visibility: hidden;
	@include mqPc {
    visibility: visible;
    position: static;
		height: auto;
		z-index: 1;
		margin-top: 5px;
	}
	&__contents{
		height: 100%;
		background: rgba(0,0,0,.5);
		width: 100%;
		opacity: 0;
		display: none;
		transition: opacity .2s ease-in ;
		@include mqPc {
			opacity: 1;
			height: auto;
			background: none;
			display: block;
		}
		&--sp{
			margin-bottom: 20px;
			@include mqPc {
				display: none;
			}
			.p-header-navigation__container{
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}
	&__toggle{
		padding: 18px 20px;
		max-width: 64px;
		height: 50px;
		overflow: hidden;
		cursor: pointer;
		@include mqPc {
			display: none;
		}
		span{
			background: #333;
			height: 1px;
			width: 18px;
			display: block;
			position: relative;
			transition: transform ease-out .3s;
			z-index: 0;
			&:nth-of-type(2){
				margin: 6px 0;
			}
		}
	}
	&__close{
		position: absolute;
		top: 20px;
		left: 88.8%;
		@include mqPc {
			display: none;
		}
	}
}
.p-header-navigation{
	width: 83.38%;
	height: 100%;
	background: #fff;
	transform: translateX(-100%);
	transition: transform .1s linear;
	background: #F8F8F8;
	@include mqPc {
		display: block;
		transform: none;
		overflow: unset;
		height: auto;
		width: 100%;
		background: none;
	}
	&__inner{
		overflow-y: scroll;
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		padding-bottom: 0px;
		@include mqPc {
			overflow: visible;
			position: static;
			padding-bottom: 0;
		}
	}
	&__list{
		@include mqPc {
			display: flex;
			justify-content: center;
			align-items: flex-start;
		}
	}
	&__item{
		font-size: 1.6rem;
		line-height: 1.25;
		border-bottom: 1px solid #F8F8F8;
		background: #fff;

		@media screen and (min-width:1025px) and (max-width:1100px){
			font-size: 1.2rem;
		}
		@include mqPc {
			border-bottom: none;
			font-size: 1.4rem;
			background: none;
		}
		&.is-show{
			.p-header-navigation__container{
				@include mqPc {
					opacity: 1;
					visibility: visible;
					z-index: 10;
					padding-top: 65px;
					padding-bottom: 50px;
					height: auto;
					transition: opacity .3s ease,visibility .3s ease, height .3s ease, z-index .3s ease;
					box-shadow: 0 4px 8px rgba(0,0,0,.2);
				}
			}
			.p-header-subNavigation__list{
				@include mqPc {
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					flex-wrap: wrap;
					padding-bottom: 40px;
					width: calc(100% + 30px);
					margin-left: -15px;
				}
			}
			.p-header-subNavigation__item{
				@include mqPc {
						text-align: center;
						margin: 20px 15px 0 15px;
				}
				p{
					@include mqPc {
						font-size: 1.2rem;
						margin-top: 11px;
					}
				}
			}
			.p-related-keywords{
				@include mqPc {
					padding-top: 40px;
					border-top: 1px solid #ccc;
				}
			}
			.c-icon--arrowB{
				transform: translateX(24px) rotate(0deg);
			}
		}


	}
	&__link{
		padding: 21px 48px 19px 21px;
		display: block;
		position: relative;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex: 1 0 100%;
		max-width: 100%;
		@include mqPc {
			padding: 24px 10px;
			display: block;
		}
		&.is-open{
			border-bottom: 1px solid #F8F8F8;
			&+.p-header-navigation{
				transition: height linear .3s;
			}
			.c-icon--arrowB{
				transform: translateX(24px) rotate(0deg);
			}
		}
		.c-icon--arrowB,
		.c-icon--arrowR{
			@include mqPc {
				display: none;
			}
		}
		.c-icon--user{

		}
		.p-header-navigation__label{
			flex: 1 0 auto;
			padding-left: 10px;
		}
	}
	&__container{
    height: 0;
		overflow: hidden;
		transition: height linear .3s;
		padding-left: 0px;
		padding-right: 0px;
		@include mqPc {
			height: auto;
			overflow: auto;
			visibility: hidden;
			opacity: 0;
			transition: opacity .3s ease,visibility .3s ease, height .3s ease, z-index .3s ease;
			z-index: -1;
			position: absolute;
			width: 100%;
			height: 0;
			left: 0;
			right: 0;
			top: 100%;
			background: #fff;
			padding-left: 40px;
			padding-right: 40px;
		}
	}
	.c-form{
		padding-top: 11px;
		padding-bottom: 40px;
		display: block;
	}
	.c-form__label{
		font-size: 1.2rem;
		line-height: 1.5;
		display: block;
	}
	.c-form__input{
    display: block;
    margin-top: 11px;
    border-radius: 4px;
    font-size: 1.2rem;
    line-height: 1.5;
    padding: 11px 5px;
	background: #eee;
	}
	.c-form__field{
		&:nth-of-type(n+2){
			margin-top: 11px;
		}
		&+.c-button--submit{
			margin-top: 30px;
		}
	}
	.c-button--submit{
		width: 100%;
		border-radius: 4px;
		text-align: center;
		font-size: 1.4rem;
		line-height: 1.35;
		padding: 11px 3px;
		background: #000;
		color: #fff;
	}
}

@keyframes accodion {
	1%{
		height: 0;
	}
	100%{
		height: 100%;
	}
}
.p-header-subNavigation{
	&__list{
		@include mqPc {
			margin-top: -25px;
		}
	}
	&__item{
		font-size: 1.4rem;
		line-height: 1.4;
		width: 100%;
		max-width: 100%;
		@include mqPc {
			width: calc(12.5% - 30px);
			max-width: 130px;
		}
	}
	&__link{
		padding: 12px 23px;
		display: block;
		@include mqPc {
			padding: 5px;
		}
	}
}

.p-header-dropdown{
	position: absolute;
	z-index: -1;
	visibility: hidden;
	top: 100%;
	left: 0;
	width: 100%;
	opacity: 0;
	transition: opacity .3s ease,visibility .3s ease;
	&__inner{
		background: #fff;
		position: relative;
		padding: 0 20px 50px 20px;
		@include mqPc {
			padding-top: 31px;
		}
	}
	&__close{
		text-align: center;
		font-size: 1.6rem;
		line-height: 1.25;
		padding-bottom: 30px;
		@include mqPc {
			display: none;
		}
		.c-button--close{
			margin-top: 20px;
			padding: 10px 20px;
		}
		.c-icon--close{
			width: 10px;
			height: 10px;
			display: inline-block;
			margin-left: 10px;
		}
	}
}
.p-searchbox{
	padding-top: 6px;
	@include mqPc {
		padding-top: 0;
	}
	.c-form{
		display: flex;
		position: relative;
		@include mqPc {
			padding-right: 50px;
		}
	}
	.c-input{
		width: 100%;
		background: none;
		padding: 15px 0;
		border-bottom: 1px solid #1C1C1C;
		font-size: 1.4rem;
		@include mqPc {
			font-size: 1.8rem;
			line-height: 1.33;
			padding: 11px 0;
		}
	}
	.p-button--search{
    position: absolute;
    right: 0;
		top: 50%;
		width: 18px;
		height: 20px;
		transform: translateY(-50%);
		border: none;
		padding: 0;
		@include mqPc {
			padding: 5px 10px;
			width: 49px;
			height: 42px;
		}
	}
	.c-icon--search{
		width: 18px;
		height: 20px;
		position: absolute;
    top: 0;
    left: 0;
		@include mqPc {
			position: static;
			width: 29px;
			height: 32px;
		}
	}
}
.p-dropdown-user{
	max-width: 680px;
	margin-right: auto;
	margin-left: auto;
	&>.p-dropdown-user__heading{
		padding: 0 30px;
	}
	&__heading{
		font-size: 1.6rem;
		line-height: 1.25;
		display: flex;
    justify-content: flex-start;
		align-items: center;
		&+.p-dropdown-user__container{
			margin-top: 24px;
		}
		svg{
			margin-right: 10px;
		}
	}
	&__wrap{
		display: flex;
		justify-content: center;
		position: relative;
		width: 100%;
		&+.c-button{
			margin-top: 30px;
		}
	}
	&__box{
    display: flex;
    position: relative;
    flex: 1 0 auto;
    min-width: calc(33.3% - 60px);
    flex-direction: column;
    max-width: 100%;
    padding: 0 30px;
    font-size: 1.4rem;
    line-height: 1.3;
		&:nth-of-type(n+2){
			&::after{
				content: "";
				display: block;
				width: 1px;
				position: absolute;
				height: 100%;
				bottom: 0;
				top: 0;
				left: 0%;
				background: #ddd;
			}
		}
	}
	&__list{
		border-top: 1px solid #ddd;
	}
	&__item{
		border-bottom: 1px solid #ddd;
	}
	&__link{
		padding: 22px 27px 22px 0;
		display: block;
		display: flex;
    justify-content: space-between;
		align-items: center;
		.c-icon--arrowR{
			transform: translateX(20px) rotate(90deg);
		}
	}
	.c-button{
		margin-right: auto;
		margin-left: auto;
		max-width: 180px;
		width: 100%;
		display: block;
	}
	.c-button--default{
		margin-top: 33px;
	}
	.c-form__input{
		border-radius: 4px;
		padding: 8px 4px;
		background: #eee;
	}
	.c-form__label{
		&+.c-form__input{
			margin-top: 10px;
		}
	}
	.c-form__field{
		&+.c-form__field{
			margin-top: 24px;
		}
	}
	.c-form__link--attention{
		font-size: 1.2rem;
		line-height: 1.16;
		margin-top: 13px;
		display: inline-block;
	}
}
