.p-column{
	&--1-2{
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-direction: column;
		@include mqPc {
			flex-direction: row;
			justify-content: space-between;
		}
		.p-column__item{
			width: 100%;
			flex: 1 1 100%;
			max-width: 100%;
			@include mqPc {
				width: calc(50% - 10px);
				flex: 0 1 calc(50% - 10px);
				max-width: calc(50% - 10px);
			}
		}
	}
	&--1{
		.p-column__item{
			width: 100%;
		}
	}
}

.help{
	.p-column--1-2{
		border-bottom: 1px solid #ddd;
		@include mqPc {
			border-bottom: none;
		}
	}
}
