.p-modal {
  visibility: hidden;
  opacity: 0;
  z-index: -10;
  transition: opacity .3s ease-out;

  &.is-show {
    visibility: visible;
    z-index: 1021;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity .3s ease-in;

    .p-modal__overlay {
      background: rgba(0, 0, 0, 0.3);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
		}

		.p-modal__button {
			top: 10px;
			right: 10px;
			@include mqPc {
				top: -35px;
				right: -35px;
			}
		}
	}
	&__wrap {
		position: relative;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		width: 95%;
		max-width: 800px;
		@include mqPc {
			width: 80%;
		}
	}
}


.p-modal__item {
  display: none;
  background: #fff;
  border-radius: 4px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;

  &.is-show {
    display: block;
  }
}

.p-modal__container {
	padding: 15px 15px 20px;
	@include mqPc {
		padding: 15px 15px 25px 15px;
	}
}

.p-modal__heading {
  border-bottom: 1px solid #f6f6f6;
  font-weight: bold;
	font-size: 16px;
	padding: 0 25px 10px 0;
	@include mqPc {
		padding: 0 0 10px 0;
	}

  + .p-modal__content {
    margin-top: 15px;
  }
}

.p-modal__content {
  font-size: 14px;
}

.p-modal__button {
  position: relative;
	width: 35px;
	height: 35px;
	display: none;
}

.p-modal__button {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	background: #fff;
	border-radius: 50%;
		&::after,
		&::before {
			content: "";
			display:block;
			position: absolute;
			width: 2px;
			height: 25px;
			background: #000;
			top: calc(50% - 12.5px);
		}
		&::after {
			transform: rotate(45deg);
		}
		&::before {
			transform: rotate(-45deg);
		}
	}


