.l-container {
	margin-top: 30px;
  @include mqPc {
    width: 100%;
    max-width: 2000px;
    margin: 70px auto 0 auto;
    padding: 0 0px 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
  }
}

.top,
.product{
	.l-container {
		margin-top: 0px;
		padding-bottom: 0;
	}
}

.p-breadcrumb{
	&+.l-container{
		@include mqPc {
			margin-top: 35px;
		}
	}
}

.l-container__sidebar {
  flex: 0 1 100%;
  @include mqPc {
    flex: 0 1 15%;
		min-width: 180px;
		padding-bottom: 120px;
  }
}

.l-container__main {
  flex: 1 0 100%;
  max-width: 100%;
}

.l-container__sidebar {
  &+.l-container__main {
    @include mqPc {
			flex: 1 0 calc(100% - 210px);
			max-width: calc(100% - 210px);
      margin-left: 30px;
    }
  }
}

.l-container--colmun02{
	@include mqPc {
		width: calc(100% - 80px);
		margin: 55px auto 0 auto;
	}
	@include mqTb {
		width: calc(100% - 40px);
		margin: 70px auto 0 auto;
	}
	.l-container__main {
		padding-bottom: 40px;
	}
}

.c-lower-header{
	&+.l-container{
		@include mqPc {
			margin-top: 40px;
		}
	}
}

