.c-button {
  width: 100%;
	border-radius: 4px;
	text-align: center;
  border: 1px solid #666;
  flex: 0 1 250px;
  padding: 17px 5px;
  line-height: 1.5;
  font-size: 1.6rem;
	margin: 0 5px;
	@include mqPc {
		flex: 0 1 320px;
		max-width: 320px;
	}
	&.c-button--submit {
    background: #1C1C1C;
    color: #fff;
    border-color: #1C1C1C;
	}
	&+.c-button {
		margin-top: 15px;
	}
}

.c-button--default {
  background: #1C1C1C;
  color: #ffffff;
  margin: 0 auto;
	display: block;
	border: none;
	@include mqPc {
		max-width: 320px;
	}
}

.c-form__input {
  width: 100%;
}

.p-column--1{
	.p-buttons{
		padding: 0 20px;
	}
}
