.is-abridgement{
	position: relative;
	&::after,
	&before{

	}
	&::after{

	}
	&::before{

	}
}
.p-result{
	height: 50vh;
	@include mqPc{

	}
	&__text{
		font-size: 2.4rem;
		line-height: 1.6;
		text-align: center;
		margin-top: 45px;
		padding-bottom: 45px;
	}
}
