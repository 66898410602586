.p-media{
	width: 100%;
	padding: 0 15px;
	border-top: 1px solid #ddd;
	@include mqPc {
		border-top: none;
		padding: 0 ;
	}
	&__list{
		@include mqPc {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
		}
	}
	&__item{
		font-size: 1.2rem;
		line-height: 1.66;
		border-bottom: 1px solid #ddd;
		@include mqPc {
			flex: 0 1 calc(33.3% - 16.667px);
			width: calc(33.3% - 16.667px);
			padding: 22px 0 23px 0;
			border-right: 1px solid #ddd;
			border-bottom: none;
			margin-right: 22px;
		}
		&:nth-last-of-type(1){
			border-right: none;
		}
	}
	&__link{
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 20px 0;
	}
	&__data{
		color: #888888;
		font-size: 1.2rem;
	}
	&__images{
		width: 60px;
		flex: 0 0 60px;
		max-width: 60px;
		margin-right: 20px;
		@include mqPc {
			width: 80px;
			flex: 0 0 80px;
			max-width: 80px;
		}
	}
	&__info{
		flex: 1 0 calc(100% - 80px);
		width: calc(100% - 80px);
		@include mqPc {
			font-size: 1.6rem;
			line-height: 1.625;
			padding-right: 30px;
			flex: 1 0 calc(100% - 100px);
			width: calc(100% - 100px);
		}
	}
	&__text{
		margin-top: 5px;
	}

	&--large{
		.p-media__item{
			display: block;
		}
		.p-media__images{
			width: 100%;
			flex: 1 0 100%;
			max-width: 100%;
			margin-right: 0;
		}
		.p-media__info{
			width: 100%;
			flex: 1 0 100%;
			max-width: 100%;
			margin-right: 0;
			margin-top: 25px;
		}
		.p-media__header{
			&+.p-media__content{
				margin-top: 25px;
				padding-top: 25px;
			}
		}
		.p-media__content{
			.p-media{
				margin-top: 25px;
				padding: 0;
			}
		}
		.p-media__heading{
			font-size: 1.6rem;
			line-height: 1.625;
		}
		.c-time{
			&+.p-media__heading{
				margin-top: 10px;
			}
		}
	}
}
