.c-page__heading {
  font-size: 2.8rem;
  line-height: 1.1;
  font-weight: normal;
  text-align: center;
  padding: fslh-spacing(28px, 2.8rem, 1.1) 0 fslh-spacing(40px, 2.8rem, 1.1) 0;

  @include mqPc {
    font-size: 3.2rem;
    line-height: 1.4;
    padding: 0 0 fslh-spacing(40px, 2.8rem, 1.1) 0;
  }
}

.c-heading{
	font-size: 2.8rem;
	line-height: 1.6;
	text-align: center;
	font-family: $baseFontsFamilyEN;
	letter-spacing: .08em;
	font-weight: bold;
	@include mqPc{
		font-size: 3.2rem;
	}
}

.p-pagetitle__title{
	font-size: 2.4rem;
	line-height: 1.33;
	text-align: center;
	font-weight: normal;
	@include mqPc{
		font-size: 3,2rem;
		line-height: 1.4;
	}
	&+.p-pagetitle__text{
		margin-top: 25px;
		max-width: 1000px;
		@include mqPc{
			margin-top: 35px;
		}
	}
	&+.p-pagetitle__banner{
		margin-top: 25px;
		@include mqPc{
			margin-top: 25px;
		}
	}
}

.p-pagetitle__banner{
	&+.p-pagetitle__title{
		margin-top: 25px;
		@include mqPc{
			margin-top: 35px;
		}
	}
	&+.p-pagetitle__text{
		margin-top: 20px;
		@include mqPc{
			margin-top: 35px;
		}
	}
}
.p-pagetitle__banner,
.p-pagetitle__title,
.p-pagetitle__text{
	&+.p-pagetitle-link{
		margin-top: 20px;
		@include mqPc{
			margin-top: 35px;
		}
	}
}

.p-pagetitle-link{
	&+.p-pagetitle__banner,
	&+.p-pagetitle__title,
	&+.p-pagetitle__text{
		margin-top: 20px;
		@include mqPc{
			margin-top: 35px;
		}
	}
}

.p-pagetitle-list__list{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	@include mqPc{
		justify-content: center;
	}
}
.p-pagetitle-list__item{
	margin: 5px 3px;
	&:nth-of-type(n+2){
		border-left: 1px solid #ddd;
	}
}
.p-pagetitle-list__link{
	padding: 3px 15px;
}

.p-pagetitle__text{
	font-size: 1.4rem;
	line-height: 1.8;
	padding: 0 20px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	@include mqPc{
		padding: 0;
	}
	a{
		text-decoration: underline;
	}
	&+.p-pagetitle__banner{
		margin-top: 20px;
		@include mqPc{
			margin-top: 35px;
		}
	}

}

.p-pagetitle{
	max-width: 2000px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	@include mqPc{
		width: calc(100% - 80px);
	}
}
.c-title{
	font-size: 2.4rem;
	line-height: 1.33;
	text-align: center;
	font-weight: normal;
	@include mqPc{
		font-size: 3.2rem;
		line-height: 1.4;
	}
	span{
		display: block;
		font-size: 1.4rem;
		margin-top: 5px;
		@include mqPc{
			font-size: 1.8rem;
			margin-top: 5px;
		}
	}
}

.c-lower-header{
	margin-top: 35px;
	padding-bottom: 40px;
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	@include mqTb{
		padding-left: 30px;
		padding-right: 30px;
	}
	@include mqPc{
		margin-top: 40px;
		padding-bottom: 55px;
		padding-left: 40px;
		padding-right: 40px;
	}
}

.c-lower-heading{
	font-family: $baseFontsFamilyEN;
	font-size: 2.8rem;
	font-weight: 700;
	letter-spacing: .08em;
	line-height: 1.6;
	text-align: center;
	@include mqPc{
		font-size: 3.2rem;
	}
	span{
		font-family: $baseFontsFamily;
		display: block;
		font-size: 1.4rem;
		margin-top: 5px;
		font-weight: normal;
		@include mqPc{
			font-size: 1.8rem;
			margin-top: 5px;
		}
	}
}

.p-breadcrumb{
	&+.p-pagetitle{
		margin-top: 35px;
		@include mqPc{
			margin-top: 40px;
		}
	}
}

.p-pagetitle{
}





