.p-card{
	&--onText{
		@include mqPc {
			padding-left: 0px;
			padding-right: 0px;
			width: calc(100% + 20px);
			margin-left: -10px;
		}
		.p-card__text{
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba($color: #1C1C1C, $alpha: 0.7);
			color: #fff;
			padding: 10px;
		}
	}
	&__list{
		display: flex;
		justify-content: flex-start;
		@include mqPc {

		}
	}
	&__item{
		position: relative;
		@include mqPc {
			margin: 0 10px;
			flex: 0 1 calc(50% - 20px);
			max-width: calc(50% - 20px);
		}
	}
	&__link{
		display: block;
	}
}
