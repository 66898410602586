.p-productList-container{
	&+.p-pagenation{
		&__list {
			@include mqPc {
				justify-content: flex-end;
			}
		}
	}
}

.p-pagenation{
	padding: 0 20px;
	font-family: $baseFontsFamilyEN;
	@include mqPc {
		padding: 0;
	}
	&__list{
		display: flex;
		justify-content: center;
		align-items: stretch;
		@include mqPc {
			justify-content: flex-end;
		}
	}
	&__item{
		border: 1px solid #ddd;
		border-radius: 4px;
		margin: 0 1.48%;
		font-size: 1.6rem;
		line-height: 1.43;
		flex: 0 1 9.52%;
		text-align: center;
		display: flex;
    align-self: normal;
    justify-content: center;
    align-items: center;
		@include mqPc {
			margin: 0 4px;
			flex: 0 0 30px;
			max-width: 30px;
		}
		&--omitted{
			border: none;
			margin: 0 -1.48%;
			@include mqPc {
				margin: 0 .5px;
			}
		}
		&--next{
			margin-right: 0;
			margin-left: auto;
			.c-icon--svg{
				transform: rotate(90deg) translateX(0%);
			}
		}
		&--prev{
			margin-left: 0;
			margin-right: auto;
			.c-icon--svg{
				transform: rotate(-90deg) translateX(0%);
			}
		}
		&--next,
		&--prev{
			background: #1C1C1C;
			border: none;
			@include mqPc {
				flex: 0 0 50px;
				max-width: 50px;
				.p-pagenation__link{
					padding-left: 22px;
					padding-right: 22px;
				}
			}
		}
		.c-icon--svg{
			width: 8px;
			height: 4px;
			stroke: #fff;
			stroke-width: 2px;
			@include mqPc {
				width: 9px;
				height: 5px;
				stroke-width: 2px;
			}
		}
		&.is-current{
			background: #ddd;
			.p-pagenation__link{
				color: #fff;
			}
		}
	}
	&__link{
		display: block;
		padding: 6.5px 0px;
		@include mqPc {
			padding: 4px 0px;
		}
	}
}

.p-productList-container{
	&+.p-pagenation{
		margin-top: 40px;
		@include mqPc {
			padding-bottom: 40px;
		}
	}
}
.l-lower-container.l-container--colmun02{
	&__inner{
		.p-pagenation{
			width: calc(100% + 40px);
			margin-left: -20px;
		}
	}
}
.c-list--colmun02-04,
.c-list--colmun02-03,
.p-archiveList{
	&+.p-pagenation{
		margin-top: 40px;
	}
}
