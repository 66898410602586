.c-icon--arrow,
.c-icon--arrowB,
.c-icon--arrowR{
	stroke: #000;
	width: 14px;
	height: 7px;
}
.c-icon--arrowB{
	transform: translateX(24px) rotate(180deg);
}
.c-icon--arrowR{
	transform: translateX(24px) rotate(90deg);
}
