.p-productList-head{
	&+.p-productList-container{
		margin-top: 20px;
	}
}
.p-search-condition{
	font-size: 1.2rem;
	line-height: 1.66;
	display: inline-block;
	padding: 0 20px;
	@include mqPc{
		padding-left: 0;
		padding-right: 0;
	}
	&__list{
		display: inline;
	}
	&__heading{
		padding: 10px 0;
		display: inline-block;
	}
	&__item,
	&__item--none{
		padding: 6.5px 28px 6.5px 10px;
		border-radius: 4px;
		margin: 0 6px 10px 0;
		display: inline-block;
		position: relative;

		.c-icon--delete{
			width: 12px;
			height: 12px;
			padding: 3px;
			display: block;
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			svg{
				stroke-width: 3px;
				width: 100%;
				height: 100%;
				position: relative;
				transform: translateY(0%);
			}
		}
		&--none{
			display: inline-block;
			font-size: 1.1rem;
		}
	}
	&__item{
		background: #eee;
		@include mqPc {
			cursor: pointer;
		}
	}
	&+.p-search-lineupChanger{
		margin-top: 20px;
		@include mqPc{
			margin-top: 5px;
		}
	}
}

.p-search-lineupChanger{
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 0 20px;
	@include mqPc{
		padding-left: 0;
		padding-right: 0;
	}
	&__sort{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex: 1 1 64.533%;
		max-width: 64.533%;
		.c-search-sort__select{
			font-size: 1.2rem;
			line-height: 1.83;
			margin-right: 8.26%;
			position: relative;
			&::after{
				display: block;
				position: absolute;
				right: 10px;
				top: calc(50% - 3px);
				transform: rotate(180deg) translateY(-50%);
				width: 9px;
				height: 5px;
				background: url(./../images/common_icon_arrow.svg) no-repeat center center;
				content: "";
				background-size: contain;
			}
			@include mqPc{
				margin-right: 20px;
			}
		}

		.c-select{
			padding: 9.5px 34px 9.5px 6px;
			@include mqPc{
				padding: 7px 52px 7px 14px;
			}
		}
	}
	&__column{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex: 0 1 26%;
		max-width: 90px;
		padding-top: 10px;
		@include mqPc {
			display: none;
		}
		.p-search-columnSwitch__item{
			margin-left: 22.7%;
			line-height: 1;
			cursor: pointer;
			&.is-current{
				svg{
					fill: #1C1C1C;
				}
			}
		}
	}
	&+.p-search-count{
		margin-top: 24px;
		@include mqPc{
			margin-top: 32px;
		}
	}
}

.c-search-sort__select{
	&--type{

	}
	&--color{

	}
	.c-select{
		border: 1px solid #ddd;
		border-radius: 4px;
	}
}


.p-search-count{
	padding: 0 20px;
	display: flex;
	justify-content: flex-start;
	align-items: baseline;
	font-size: 1.2rem;
	line-height: 1.33;
	@include mqPc{
		padding-left: 0;
		padding-right: 0;
	}
	&__item{
		font-size: 1.6rem;
		line-height: 1.312;
	}
	&__all{

	}
	span{

	}
}
