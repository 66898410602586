.p-breadcrumb{
	display: none;
	@include mqPc {
		display: block;
		margin-top: 30px;
		max-width: calc(100% - 80px);
		margin-left: auto;
		margin-right: auto;
	}
	&__list{
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	&__item{
		@include mqPc {
			font-size: 1.3rem;
			line-height: 1.23;
			display: flex;
			align-items: center;
			padding-right: 15px;
			margin-right: 10px;
		}
		@include mqPc {
			.c-icon--arrowR{
				width: 8px;
				height: 4px;
				transform: translateX(14px) rotate(90deg);
			}
		}
		&:nth-last-of-type(1){
			.c-icon--arrowR{
				display: none;
			}
		}
	}
	&__link{
		text-decoration: underline;
	}
}
