.p-newsDetail{
	@include mqPc{
		width: 100%;
		max-width: 820px;
		padding: 0 10px;
		margin-left: auto;
		margin-right: auto;
	}
	&__header{

	}
	&__title{
		font-size: 2.4rem;
		line-height: 1.2;
		font-weight: bold;
		@include mqPc{
			line-height: 1.875;
		}
	}
	&__time{
		font-size: 1.2rem;
		line-height: 1.2;
		margin-top: 14px;
		display: block;
	}
	&__image{
		margin-top: 20px;
		width: calc(100% + 40px);
		margin-left: -20px;
		@include mqPc{
			width: 100%;
			margin-left: 0px;
		}
	}
	&__contents{
		margin-top: 28px;
		font-size: 1.6rem;
		line-height: 2;
		p{
			&:nth-of-type(n+2){

			}
		}
	}
	.p-lower-subHeading {
		font-size: 2rem;
		line-height: 1.6;
		margin-top: 80px;
		@include mqPc{
			font-size: 2.4rem;
			line-height: 1.875;
			margin-top: 100px;
		}
		&+.p-archiveList{
			margin-top: 32px;
		}
	}
}


.news{
	.p-archiveList{
		@include mqPc{
			width: 100%;
			max-width: 1020px;
			padding: 0 10px;
			margin-left: auto;
			margin-right: auto;
		}
		&+.p-pagenation{
			@include mqPc{
			width: 100%;
			max-width: 1020px;
			margin-right: auto;
			margin-left: auto;
			padding: 0 10px;
			}
		}
	}

}
